<template>
  <div>
    <iframe src="http://localhost/model/14?grid=1.19&sepia=3.91&shadowIntensity=0&brightness=0&contrast=0&opacity=1&blendMode=skip&blockOpacity=1&pixar=NaN&isEnvImage=0&isSkyBoxImage=0"></iframe>
    <h1>Test Page</h1>
  </div>
</template>

<script>
export default {
  name: "MainView",
}
</script>
